<template>
  <div class="results" :style="colorStyle" v-if="selectedHierarchy">
    <div class="tools-container">
      <div class="title">
        <h2>Dashboard plan de acción</h2>
      </div>
      <div class="tools-buttons">
        <template v-for="index in 2">
          <div :key="index">
            <palette
              :startColor="colors[index - 1] || 'gray'"
              @changeColor="color => changeColor(color, index - 1)"
            />
          </div>
        </template>

        <div>
          <chart-select
            v-model="chartType"
            :charts="['squareBar', 'roundBar', 'clockBar']"
          />
        </div>

        <custom-button variant="secondary" @click="downloadPPT">
          <font-awesome-icon :icon="icons.powerpoint" slot="icon" />
          PPT
        </custom-button>

        <custom-button variant="button" @click="isModalOpen = true">
          <font-awesome-icon :icon="icons.filter" slot="icon" />
          Filtrar
        </custom-button>
      </div>
    </div>

    <div class="tags-container">
      <tag-bar :filters="filters" />
    </div>

    <div class="doughnut-chart-complete-container">
      <doughnut-chart-complete
        :actionPlans="filteredActionPlans"
        :colors="colors"
        :completeIndex="completeIndex"
      />
    </div>

    <div class="doughnut-chart-expense-container">
      <doughnut-chart-expense
        :actionPlans="filteredActionPlans"
        :colors="colors"
        :expenseIndex="expenseIndex"
      />
    </div>

    <div class="impact-chart-container">
      <impact-chart
        :actionPlans="filteredActionPlans"
        :colors="colors"
        :filters="filters"
      />
    </div>

    <div class="quantity-container">
      <quantity-chart :actionPlans="filteredActionPlans" />
    </div>

    <div class="main-chart-container">
      <div class="main-chart-text">
        <div class="main-chart-display">
          <h2>Análisis de Resultados</h2>
          <h4>{{ selectedHierarchy }}</h4>
        </div>
        <div class="display-btn" @click="isReportModalOpen = true">
          <font-awesome-icon :icon="icons.plusCircle" slot="icon" />
        </div>
      </div>

      <div class="main-chart">
        <main-chart
          ref="mainChartComponent"
          :selectedHierarchy="selectedHierarchy"
          :actionPlans="filteredActionPlans"
          :chartType="chartType"
          :colors="colors"
          :filters="filters"
          :completeIndex="completeIndex"
          :expenseIndex="expenseIndex"
          :isReportModalOpen="isReportModalOpen"
          @close="isReportModalOpen = false"
        ></main-chart>
      </div>
    </div>

    <modal :isOpen="isModalOpen" @close="isModalOpen = false">
      <template slot="title">Filtrar</template>
      <div class="filters-container">
        <filter-box
          :startHierarchy="selectedHierarchy || ''"
          :startFilters="filters || {}"
          v-on:editFilters="handleEditFilter"
          v-on:closeModal="isModalOpen = false"
        ></filter-box>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import DoughnutChartComplete from "../components/dashboardAP/DoughnutChartComplete.vue";
import DoughnutChartExpense from "../components/dashboardAP/DoughnutChartExpense.vue";
import MainChart from "../components/dashboardAP/MainChart.vue";
import ImpactChart from "../components/dashboardAP/ImpactChart.vue";
import QuantityChart from "../components/dashboardAP/QuantityChart.vue";
import ChartSelect from "../components/dashboard/ChartSelect.vue";
import Palette from "../components/dashboard/Palette.vue";
import CustomButton from "../components/CustomButton.vue";
import Modal from "../components/Modal.vue";
import FilterBox from "../components/dashboardAP/FilterBox.vue";
import TagBar from "../components/dashboard/TagBar.vue";

import {
  faSlidersH,
  faPlusCircle,
  faFilePowerpoint
} from "@fortawesome/free-solid-svg-icons";

export default {
  name: "Results",
  components: {
    DoughnutChartComplete,
    DoughnutChartExpense,
    MainChart,
    ImpactChart,
    QuantityChart,
    ChartSelect,
    Palette,
    CustomButton,
    Modal,
    FilterBox,
    TagBar
  },

  data: () => ({
    selectedHierarchy: "",
    chartType: "squareBar",
    colors: [],
    isModalOpen: false,
    filters: {},
    icons: {
      filter: faSlidersH,
      plusCircle: faPlusCircle,
      powerpoint: faFilePowerpoint
    },
    isReportModalOpen: false
  }),

  computed: {
    ...mapState({
      actionPlans: state => state.actionPlans.filter(ap => !ap.archived),
      categories: state =>
        state.filters.filters.filter(filter => filter.orgHierarchy)
    }),
    ...mapState(["company", "survey"]),

    expenseIndex() {
      let totalExpense = 0;
      let totalBudget = 0;
      this.filteredActionPlans.forEach(actionPlan => {
        totalExpense += +actionPlan.expense;
        totalBudget += +actionPlan.budget;
      });
      let expenseIndex = [];
      expenseIndex[0] =
        totalBudget == 0
          ? 100
          : totalExpense == 0
          ? 0
          : (totalExpense / totalBudget) * 100;
      expenseIndex[1] = 100 - expenseIndex[0] || 0;

      return [expenseIndex[0].toFixed(0), expenseIndex[1].toFixed(0)];
    },

    completeIndex() {
      let completeIndex = [0, 0];
      this.filteredActionPlans.forEach(actionPlan => {
        if (actionPlan.status === "Realizado") {
          completeIndex[0] += 1;
        } else completeIndex[1] += 1;
      });

      let total = completeIndex[0] + completeIndex[1];
      if (total == 0) return [0, 0];
      return [
        ((completeIndex[0] / total) * 100).toFixed(0),
        ((completeIndex[1] / total) * 100).toFixed(0)
      ];
    },

    colorStyle() {
      return {
        "--primary-selected-color": this.colors[0],
        "--secondary-selected-color": this.colors[1],
        "--nth2-selected-color": this.colors[2],
        "--nth3-selected-color": this.colors[3]
      };
    },
    filteredActionPlans() {
      let actionPlans = [...this.actionPlans];
      Object.keys(this.filters).forEach(key => {
        actionPlans = actionPlans.filter(actionPlan =>
          this.getParents(actionPlan.hierarchyName).some(member =>
            this.filters[key].includes(member)
          )
        );
      });
      return actionPlans;
    }
  },

  methods: {
    ...mapActions(["updateActionPlanColors", "fetchCurrentSurvey"]),
    downloadPPT() {
      this.$refs.mainChartComponent.downloadPPT();
    },
    changeColor(color, index) {
      let colorsCopy = [...this.colors];
      colorsCopy[index] = color;
      this.updateActionPlanColors(colorsCopy);
      this.survey.actionPlanColors = colorsCopy;
      this.colors = colorsCopy;
    },
    handleEditFilter(filters) {
      this.filters = { ...filters.selectedFilters };
      this.selectedHierarchy = filters.selectedHierarchy;
      this.isModalOpen = false;
    },
    getOption(optionName) {
      return this.categories
        .reduce((acc, cur) => {
          return acc.concat(cur.options);
        }, [])
        .filter(o => o.name === optionName)[0];
    },

    getParents(optionName) {
      let option = this.getOption(optionName);
      if (option) {
        let optionNames = [option.name];
        let parentName = option.parentOption;
        while (parentName) {
          option = this.getOption(parentName);
          optionNames.push(option.name);
          parentName = option.parentOption;
        }
        return optionNames;
      }
      return [];
    }
  },

  async mounted() {
    await this.fetchCurrentSurvey(this.company.id);
    this.colors = this.survey.actionPlanColors || ["#15b195", "#A4D6C9"];
    this.selectedHierarchy = this.categories[0].name;
  }
};
</script>

<style scoped>
.results {
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas: "tools tools tools" "tag tag tag" "quantity impact expense" "complete bar bar" "complete bar bar";
  grid-template-rows: 7% 3% 33% 19% 30%;
  grid-template-columns: 24% 50% 24%;
  column-gap: 1%;
  row-gap: 2%;
  font-size: 16px;
}

/* DOUGHNUT */

.doughnut-chart-complete-container {
  grid-area: complete;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
}

/* BAR CONTAINER */
.main-chart-container {
  padding: 1em 1em 0.2em 1em;
  grid-area: bar;
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  height: 100%;
  border: solid 3px #f7f7fc;
}

.main-chart-container > div:nth-child(2) {
  flex-grow: 1;
}

.main-chart-display h2 {
  margin-right: 1em;
  font-size: 1.2em;
}

.main-chart-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-chart {
  height: 82%;
}

/* TOOLS */
.tools-container {
  grid-area: tools;
  display: flex;
  justify-content: space-between;
  user-select: none;
}

.tools-buttons {
  display: flex;
  align-items: flex-end;
}

.tools-buttons > * {
  margin: 0 0.8em;
}

/* DOUGHNUT */

.doughnut-chart-expense-container {
  grid-area: expense;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
}

/* QUANTITY CONTAINER */
.quantity-container {
  grid-area: quantity;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
  position: relative;
}

/* DOUGHNUT */

.impact-chart-container {
  padding: 1em;
  grid-area: impact;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #f7f7fc;
}

/* TAGS CONTAINER */
.tags-container {
  padding-left: 1em;
  grid-area: tag;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tag {
  margin-right: var(--lengthSm2);
  margin-bottom: var(--lengthSm2);
  margin-top: var(--lengthSm2);
  padding: 0.4rem var(--lengthSm3);
  border-radius: var(--lengthMd3);
  background-color: #ebeef0;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  width: max-content;
}

.display-btn {
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
}

.display-btn svg {
  height: 100%;
  width: 100%;
  color: var(--gray-color1);
}

@media (max-height: 1000px) {
  .container {
    font-size: 16px;
  }
}

@media (max-height: 800px) {
  .container {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .container {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .container {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .container {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .container {
    font-size: 8px;
  }
}
</style>
