<template>
  <div class="container">
    <h2>Análisis de Resultados</h2>
    <div
      class="doughnut-chart__container"
      ref="chart"
      :style="sizeStyle"
      :class="{ small: size < 140 }"
    >
      <doughnut-chart
        :style="sizeStyle"
        :chartData="dataCollection"
        :options="{
          tooltips: { enabled: false },
          legend: {
            display: false
          },
          responsive: true,
          cutoutPercentage: 80
        }"
      ></doughnut-chart>
      <div class="ppc-percents">
        <div class="pcc-percents-wrapper">
          <span
            >{{ value ? value : completeIndex[0]
            }}<span class="percent-symbol">%</span>
          </span>
          <span v-show="label" style="font-size: 1em">{{ label }}</span>
        </div>
      </div>
    </div>
    <div class="content">
      <div>
        <h2>Realizado</h2>
        <h1 class="primary-color">{{ completeIndex[0] + "" }}%</h1>
      </div>
      <div>
        <h2>Pendiente</h2>
        <h1 class="secondary-color">{{ completeIndex[1] + "" }}%</h1>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChart from "../charts/DoughnutChart.vue";
export default {
  components: {
    DoughnutChart
  },
  data() {
    return {
      size: null,
      sizeStyle: ""
    };
  },

  props: {
    actionPlans: {
      type: Array,
      required: true
    },
    colors: {
      type: Array,
      required: true
    },
    label: {
      required: false
    },
    value: {
      required: false,
      default: false
    },
    completeIndex: {
      type: Array,
      required: true
    }
  },

  methods: {},

  mounted() {
    this.size = this.$refs.chart.clientHeight;
    this.sizeStyle = { width: this.size + "px" };
  },

  computed: {
    dataCollection() {
      return {
        labels: ["Realizado", "Pendiente"],
        datasets: [
          {
            label: "dataset",
            data: this.completeIndex,
            backgroundColor: this.colors,
            circumference: 80,
            hoverOffset: 4
          }
        ]
      };
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  font-size: 16px;
}

.container h2,
.container h3 {
  font-size: 1.2em;
}

h1 {
  font-size: 1.6em;
}

.content {
  display: flex;
  height: 20%;
  width: 100%;
  justify-content: space-around;
}

.content > div {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.primary-color {
  color: var(--primary-selected-color);
}

.secondary-color {
  color: var(--secondary-selected-color);
}

.doughnut-chart__container {
  position: relative;
  display: flex;
  align-items: center;
  height: calc(56% - 3.2em);
  margin: auto;
}

.ppc-percents {
  position: absolute;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 82%;
  height: 82%;
  background: transparent;
  text-align: center;
  display: table;
}
.ppc-percents div > span {
  display: block;
  font-size: 2.6em;
  font-weight: bold;
  width: fit-content;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin: auto;
  color: var(--primary-selected-color);
}

.small .ppc-percents span {
  font-size: 1.8em;
}

.pcc-percents-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.ppc-percents .percent-symbol {
  font-size: 0.65em;
  width: fit-content;
}

@media (max-height: 1000px) {
  .container {
    font-size: 16px;
  }
}

@media (max-height: 800px) {
  .container {
    font-size: 14px;
  }
}

@media (max-height: 700px) {
  .container {
    font-size: 13px;
  }
}

@media (max-height: 550px) {
  .container {
    font-size: 12px;
  }
}

@media (max-height: 450px) {
  .container {
    font-size: 10px;
  }
}

@media (max-height: 400px) {
  .container {
    font-size: 8px;
  }
}
</style>
