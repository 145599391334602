export const currencies = [
  {
    code: "BOB",
    description: "Boliviano",
    symbol: "Bs"
  },
  {
    code: "EUR",
    description: "Euro",
    symbol: "\u20ac"
  },
  {
    code: "PEN",
    description: "Sol",
    symbol: "S/"
  },
  {
    code: "PYG",
    description: "Guaraní",
    symbol: "\u20B2"
  },
  {
    code: "USD",
    description: "US Dollar",
    symbol: "$"
  }
];
