<template>
  <div
    v-if="isDataReady"
    class="action-plans"
    :style="{
      '--hierarchySize': categories.length || 1
    }"
  >
    <div class="flex">
      <h2>Plan de Acción</h2>
    </div>

    <div class="flex justify-between">
      <search-input v-model="search" placeholder="Buscar plan" />

      <div class="btn-container">
        <custom-button
          variant="button"
          download="planes_de_acción.csv"
          :href="downloadLink"
        >
          Excel
          <font-awesome-icon :icon="icons.excel" slot="icon" />
        </custom-button>

        <custom-button
          type="button"
          variant="primary"
          @click="isAddModalOpen = true"
        >
          <font-awesome-icon icon="plus" slot="icon" />
          Agregar Plan
        </custom-button>
      </div>
    </div>

    <div
      class="action-plans-container"
      v-if="
        true
        //categories.length
        //? Object.keys(hierarchySatisfactionIndex).length
        //: true
      "
    >
      <div class="action-plans-table">
        <div class="header row">
          <span></span>
          <span>#</span>
          <span>Plan de Acción</span>
          <span>Organización</span>
          <!-- <span>
            <font-awesome-icon icon="plus" />
          </span>
          <span>
            <font-awesome-icon icon="minus" />
          </span> -->
          <span>Dimensión</span>
          <!-- <span>
            <font-awesome-icon icon="plus" />
          </span>
          <span>
            <font-awesome-icon icon="minus" />
          </span> -->
          <span>Inicio</span>
          <span>Fin</span>
          <span>Cumplimiento</span>
          <span>Impacto</span>
          <span>Responsable</span>
          <span>Presupuesto</span>
          <span>Gasto</span>
          <span>Estado</span>
          <span>Revisado por</span>
          <span>Registro</span>
        </div>
        <template v-for="(actionPlan, index) in filteredPlans">
          <div
            :key="actionPlan.id"
            class="row"
            :class="{ done: actionPlan.status == 'Realizado' }"
          >
            <span
              ><ellipsis
                @edit="setEditOptionsModal(actionPlan)"
                @delete="$refs.deleteModal.open(actionPlan)"
                @archive="$refs.archiveModal.open(actionPlan)"
                @sendMail="$refs.sendEmailModal.open(actionPlan)"
            /></span>
            <span>{{ index + 1 }}</span>
            <span class="row-name actionPlan flex">
              <p>{{ actionPlan.text }}</p>
              <span class="tooltip"> {{ actionPlan.text }} </span>
            </span>

            <span v-if="actionPlan.hierarchyName" class="hierarchy"
              ><span :key="index" v-for="(category, index) in categories">{{
                getParents(actionPlan.hierarchyName)[index] || "---"
              }}</span></span
            >

            <span v-else class="hierarchy"><span>---</span></span>

            <!-- <span
              >{{
                hierarchySatisfactionIndex[actionPlan.hierarchyName]
                  ? hierarchySatisfactionIndex[actionPlan.hierarchyName][0]
                  : 0
              }}%</span
            >
            <span
              >{{
                hierarchySatisfactionIndex[actionPlan.hierarchyName]
                  ? hierarchySatisfactionIndex[actionPlan.hierarchyName][1]
                  : 0
              }}%</span
            > -->

            <span>{{ actionPlan.dimensionName }}</span>
            <!-- <span
              >{{
                getSatisfactionIndexBySectionAndHierarchy(
                  actionPlan.dimensionId,
                  getCategoryName(actionPlan.hierarchyName),
                  actionPlan.hierarchyName
                )[0]
              }}%</span
            >
            <span
              >{{
                getSatisfactionIndexBySectionAndHierarchy(
                  actionPlan.dimensionId,
                  getCategoryName(actionPlan.hierarchyName),
                  actionPlan.hierarchyName
                )[1]
              }}%</span
            > -->

            <span v-if="actionPlan.startDate.seconds">{{
              new Date(actionPlan.startDate.seconds * 1000)
                .toISOString()
                .split("T")[0]
            }}</span>
            <span v-else>
              {{ actionPlan.startDate.toISOString().split("T")[0] }}</span
            >

            <span v-if="actionPlan.endDate.seconds">{{
              new Date(actionPlan.endDate.seconds * 1000)
                .toISOString()
                .split("T")[0]
            }}</span>
            <span v-else>
              {{ actionPlan.endDate.toISOString().split("T")[0] }}</span
            >

            <template v-if="actionPlan.checkDate">
              <span v-if="actionPlan.checkDate.seconds">{{
                new Date(actionPlan.checkDate.seconds * 1000)
                  .toISOString()
                  .split("T")[0]
              }}</span>
              <span v-else>
                {{ actionPlan.checkDate.toISOString().split("T")[0] }}</span
              >
            </template>

            <span v-else>---</span>

            <span>{{ actionPlan.impact }}</span>

            <span>{{ actionPlan.inCharge }}</span>

            <span>
              {{
                `${getCurrencySymbol(actionPlan.currency)} ${actionPlan.budget}`
              }}
            </span>

            <span>
              {{
                `${getCurrencySymbol(actionPlan.currency)} ${
                  actionPlan.expense
                }`
              }}
            </span>

            <span>{{ actionPlan.status }}</span>

            <span>{{ actionPlan.reviewer || "---" }}</span>

            <span>
              <custom-file-input
                v-if="!actionPlan.file"
                class="btn"
                :label="'Registrar'"
                @input="e => handleInput(e, actionPlan)"
              ></custom-file-input>
              <custom-button
                v-else
                type="button"
                variant="ghost"
                @click="downloadFile(actionPlan.id)"
              >
                <font-awesome-icon :icon="icons.download" slot="icon" />
                Descargar
              </custom-button></span
            >
          </div>
        </template>
      </div>
    </div>

    <modal :isOpen="isAddModalOpen" @close="isAddModalOpen = false">
      <template slot="title">Agregar Plan de Acción</template>
      <validation-observer tag="div" v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(createActionPlan)">
          <custom-select
            v-if="categories.length"
            :label="'Jerarquia'"
            :options="
              [].concat(
                ...categories.map(category =>
                  category.options.map(option => ({
                    value: option.name,
                    id: option.name
                  }))
                )
              )
            "
            v-model="addOption.hierarchyName"
          ></custom-select>
          <custom-select
            :label="'Dimension'"
            :options="
              sections
                .filter(
                  section =>
                    section.id !== 'engagement' && section.id !== 'enps'
                )
                .map(section => ({
                  value: section.name,
                  id: section.id
                }))
            "
            v-model="addOption.dimensionId"
          ></custom-select>
          <custom-input
            :label="'Plan de Acción'"
            type="text"
            v-model="addOption.actionPlan"
            rules="required"
          />

          <custom-textarea
            :label="'Descripción'"
            type="text"
            v-model="addOption.description"
          />

          <custom-select
            :label="'Impacto'"
            :options="[
              {
                value: 'Alto',
                id: 'Alto'
              },
              {
                value: 'Moderado',
                id: 'Moderado'
              },
              {
                value: 'Bajo',
                id: 'Bajo'
              }
            ]"
            v-model="addOption.impact"
          ></custom-select>

          <div class="form-flex">
            <custom-input
              class="label__add"
              :label="'Fecha de Inicio'"
              type="date"
              v-model="addOption.startDate"
              rules="required"
            />
            <custom-input
              class="label__add"
              :label="'Fecha de Fin'"
              type="date"
              v-model="addOption.endDate"
              :rules="`required|min_date:${addOption.startDate}`"
            />
          </div>
          <div class="form-flex">
            <custom-select
              class="label__add"
              :label="'Moneda'"
              :options="
                currencies.map(currency => {
                  return { value: currency.code, id: currency.code };
                })
              "
              v-model="addOption.currency"
            />
            <custom-input
              class="label__add"
              :label="'Presupuesto'"
              type="number"
              v-model="addOption.budget"
              rules="required|positive"
            />
          </div>
          <custom-input
            :label="'Responsable'"
            type="text"
            v-model="addOption.inCharge"
            rules="required"
          />

          <div class="btn-container">
            <custom-button
              type="button"
              :variant="'text'"
              @click="isAddModalOpen = false"
            >
              Cancelar
            </custom-button>
            <custom-button type="submit" :disabled="invalid">
              Agregar Plan
            </custom-button>
          </div>
        </form>
      </validation-observer>
    </modal>

    <modal :isOpen="isEditModalOpen" @close="isEditModalOpen = false">
      <template slot="title">Editar Plan de Acción</template>
      <validation-observer tag="div" v-slot="{ handleSubmit, invalid }">
        <form @submit.prevent="handleSubmit(editPlan)">
          <custom-input
            :label="'Plan de Acción'"
            type="text"
            v-model="editOption.actionPlan"
            rules="required"
          />
          <custom-textarea
            :label="'Descripción'"
            type="text"
            v-model="editOption.description"
          />
          <custom-select
            :label="'Impacto'"
            :options="[
              {
                value: 'Alto',
                id: 'Alto'
              },
              {
                value: 'Moderado',
                id: 'Moderado'
              },
              {
                value: 'Bajo',
                id: 'Bajo'
              }
            ]"
            v-model="editOption.impact"
          ></custom-select>
          <div class="form-flex">
            <custom-input
              class="form-date"
              :label="'Fecha de Inicio'"
              type="date"
              v-model="editOption.startDate"
              rules="required"
            />
            <custom-input
              class="form-date"
              :label="'Fecha de Fin'"
              type="date"
              v-model="editOption.endDate"
              :rules="`required|min_date:${editOption.startDate}`"
            />
            <custom-input
              class="form-date"
              :label="'Fecha de Cumplimiento'"
              type="date"
              v-model="editOption.checkDate"
            />
          </div>
          <div class="form-flex">
            <custom-select
              :label="'Moneda'"
              :options="
                currencies.map(currency => {
                  return { value: currency.code, id: currency.code };
                })
              "
              v-model="editOption.currency"
            />
            <custom-input
              :label="'Presupuesto'"
              type="number"
              v-model="editOption.budget"
              rules="required|positive"
            />
            <custom-input
              :label="'Gasto'"
              type="number"
              v-model="editOption.expense"
              rules="required|positive"
            />
          </div>
          <custom-input
            :label="'Responsable'"
            type="text"
            v-model="editOption.inCharge"
            rules="required"
          />
          <custom-select
            :label="'Estado'"
            :options="[
              { value: 'Realizado', id: 'Realizado' },
              { value: 'Pendiente', id: 'Pendiente' }
            ]"
            v-model="editOption.status"
          ></custom-select>

          <custom-input
            :label="'Revisado por'"
            type="text"
            v-model="editOption.reviewer"
          />
          <div class="btn-container">
            <custom-button
              type="button"
              :variant="'text'"
              @click="isEditModalOpen = false"
            >
              Cancelar
            </custom-button>
            <custom-button type="submit" :disabled="invalid"
              >Guardar Plan</custom-button
            >
          </div>
        </form>
      </validation-observer>
    </modal>

    <send-action-plan-modal ref="sendEmailModal" />

    <delete-action-plan-modal ref="deleteModal" />
    <archive-action-plan-modal ref="archiveModal" />
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput.vue";
import { mapActions, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";
import Modal from "@/components/Modal.vue";
import CustomSelect from "@/components/CustomSelect.vue";
import CustomInput from "@/components/CustomInput.vue";
import CustomTextarea from "@/components/CustomTextarea.vue";
import CustomButton from "@/components/CustomButton.vue";
import CustomFileInput from "@/components/CustomFileInput.vue";
import Ellipsis from "@/components/Ellipsis.vue";
import { currencies } from "../currencies";

import { faFileDownload, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import SendActionPlanModal from "../components/SendActionPlanModal.vue";
import DeleteActionPlanModal from "../components/DeleteActionPlanModal.vue";
import ArchiveActionPlanModal from "../components/ArchiveActionPlanModal.vue";

export default {
  name: "ActionPlan",

  components: {
    Modal,
    CustomSelect,
    CustomInput,
    CustomButton,
    Ellipsis,
    ValidationObserver,
    CustomFileInput,
    SendActionPlanModal,
    DeleteActionPlanModal,
    ArchiveActionPlanModal,
    SearchInput,
    CustomTextarea
  },

  data() {
    return {
      isDataReady: false,
      search: "",
      email: "",
      //hierarchySatisfactionIndex: {},
      currencies: currencies,
      hiddenRows: [],
      defaultAddOption: {},
      addOption: {
        hierarchyName: "",
        budget: "0",
        expense: "0",
        startDate: "2022-01-01",
        endDate: "2022-01-01",
        actionPlan: "",
        inCharge: "",
        currency: "",
        dimensionId: "",
        impact: "Alto",
        description: ""
      },
      editOption: {
        id: "",
        hierarchyName: "",
        budget: "0",
        expense: "0",
        startDate: "",
        endDate: "",
        checkDate: "",
        actionPlan: "",
        status: "",
        inCharge: "",
        currency: "",
        impact: "",
        description: "",
        reviewer: ""
      },
      isAddModalOpen: false,
      isEditModalOpen: false,
      isMailModalOpen: false,
      selectedFile: "",
      icons: {
        download: faFileDownload,
        excel: faFileExcel
      }
    };
  },

  methods: {
    ...mapActions("filters", ["fetchFilters"]),
    ...mapActions("sections", ["fetchSections"]),
    ...mapActions("scale", ["fetchScale"]),

    ...mapActions([
      "addActionPlan",
      "fetchActionPlans",
      "editActionPlan",
      "getFile",
      "fetchAllAnswers",
      "fetchCurrentSurvey",
      "uploadActionPlanFile",
      "sendActionPlanEmail"
    ]),

    handleInput(e, actionPlan) {
      this.selectedFile = e;
      this.uploadActionPlanFile({ file: this.selectedFile, id: actionPlan.id });
      this.editActionPlan({
        ...actionPlan,
        file: true,
        fileName: this.selectedFile.name
      });
    },

    CSVtoArray(text) {
      var re_valid = /^\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*(?:,\s*(?:'[^'\\]*(?:\\[\S\s][^'\\]*)*'|"[^"\\]*(?:\\[\S\s][^"\\]*)*"|[^,'"\s\\]*(?:\s+[^,'"\s\\]+)*)\s*)*$/;
      var re_value = /(?!\s*$)\s*(?:'([^'\\]*(?:\\[\S\s][^'\\]*)*)'|"([^"\\]*(?:\\[\S\s][^"\\]*)*)"|([^,'"\s\\]*(?:\s+[^,'"\s\\]+)*))\s*(?:,|$)/g;
      // Return NULL if input string is not well formed CSV string.
      if (!re_valid.test(text)) return null;
      var a = []; // Initialize array to receive values.
      text.replace(
        re_value, // "Walk" the string using replace with callback.
        function(m0, m1, m2, m3) {
          // Remove backslash from \' in single quoted values.
          if (m1 !== undefined) a.push(m1.replace(/\\'/g, "'"));
          // Remove backslash from \" in double quoted values.
          else if (m2 !== undefined) a.push(m2.replace(/\\"/g, '"'));
          else if (m3 !== undefined) a.push(m3);
          return ""; // Return empty string.
        }
      );
      // Handle special case of empty last value.
      if (/,\s*$/.test(text)) a.push("");
      return a.join(" ");
    },

    getReportCSV() {
      let csv = [];
      csv.push([
        "Plan de acción",
        "Organización",
        // "+",
        // "-",
        "Dimensión",
        // "+",
        // "-",
        "Inicio",
        "Fin",
        "Cumplimiento",
        "Impacto",
        "Responsable",
        "Presupuesto",
        "Gasto",
        "Estado",
        "Revisado por"
      ]);
      let temp = [];
      this.actionPlans.forEach(plan => {
        temp.push(
          "\n" + this.CSVtoArray(plan.text),
          this.CSVtoArray(plan.hierarchyName) || "---",
          // this.hierarchySatisfactionIndex[plan.hierarchyName]
          //   ? this.hierarchySatisfactionIndex[plan.hierarchyName][0]
          //   : 0,
          // this.hierarchySatisfactionIndex[plan.hierarchyName]
          //   ? this.hierarchySatisfactionIndex[plan.hierarchyName][1]
          //   : 0,
          plan.dimensionName,
          // this.getSatisfactionIndexBySectionAndHierarchy(
          //   plan.dimensionId,
          //   this.getCategoryName(plan.hierarchyName),
          //   plan.hierarchyName
          // )[0] + "%",
          // this.getSatisfactionIndexBySectionAndHierarchy(
          //   plan.dimensionId,
          //   this.getCategoryName(plan.hierarchyName),
          //   plan.hierarchyName
          // )[1] + "%",
          plan.startDate.seconds
            ? new Date(plan.startDate.seconds * 1000)
                .toISOString()
                .split("T")[0]
            : plan.startDate.toISOString().split("T")[0],
          plan.endDate.seconds
            ? new Date(plan.endDate.seconds * 1000).toISOString().split("T")[0]
            : plan.endDate.toISOString().split("T")[0],
          plan.checkDate
            ? plan.checkDate.seconds
              ? new Date(plan.checkDate.seconds * 1000)
                  .toISOString()
                  .split("T")[0]
              : plan.checkDate.toISOString().split("T")[0]
            : "---",
          plan.impact,
          plan.inCharge,
          `${this.getCurrencySymbol(plan.currency)} ${plan.budget}`,
          `${this.getCurrencySymbol(plan.currency)} ${plan.expense}`,
          plan.status,
          plan.reviewer || "---"
        );
      });
      csv.push(temp);

      return csv;
    },

    downloadFile(id) {
      this.getFile(id).then(file => {
        file
          .getDownloadURL()
          .then(url => {
            const xhr = new XMLHttpRequest();
            xhr.responseType = "blob";
            const link = document.createElement("a");
            link.download = file.name;
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          })
          .catch(() => {});
      });
    },

    getOption(optionName) {
      return this.categories
        .reduce((acc, cur) => {
          return acc.concat(cur.options);
        }, [])
        .filter(o => o.name === optionName)[0];
    },

    getParents(optionName) {
      let option = this.getOption(optionName);
      if (option) {
        let optionNames = [option.name];
        let parentName = option.parentOption;
        while (parentName) {
          option = this.getOption(parentName);
          optionNames.push(option.name);
          parentName = option.parentOption;
        }
        return optionNames.reverse();
      }
      return [];
    },

    getSectionName(id) {
      let name = this.sections.filter(section => section.id === id)[0].name;
      return name;
    },

    hideRow(id) {
      if (this.hiddenRows.includes(id)) {
        this.hiddenRows = this.hiddenRows.filter(row => row !== id);
      } else this.hiddenRows.push(id);
    },

    getFormattedDate(date) {
      var year = date.getFullYear();

      var month = (1 + date.getMonth()).toString();
      month = month.length > 1 ? month : "0" + month;

      var day = date.getDate().toString();
      day = day.length > 1 ? day : "0" + day;

      return year + "-" + month + "-" + day;
    },

    async createActionPlan() {
      let actionPlan = {
        text: this.addOption.actionPlan,
        hierarchyName: this.addOption.hierarchyName,
        startDate: new Date(this.addOption.startDate + "T00:00"),
        endDate: new Date(this.addOption.endDate + "T00:00"),
        checkDate: "",
        budget: this.addOption.budget,
        expense: 0,
        inCharge: this.addOption.inCharge,
        currency: this.addOption.currency,
        status: "Pendiente",
        dimensionId: this.addOption.dimensionId,
        dimensionName: this.getSectionName(this.addOption.dimensionId),
        impact: this.addOption.impact,
        file: false,
        reviewer: "",
        description: this.addOption.description
      };
      this.addOption = { ...this.defaultAddOption };
      this.addActionPlan(actionPlan);
      this.isAddModalOpen = false;
    },

    setEditOptionsModal(actionPlan) {
      let startDate = actionPlan.startDate.seconds
        ? new Date(actionPlan.startDate.seconds * 1000)
        : actionPlan.startDate;
      let endDate = actionPlan.endDate.seconds
        ? new Date(actionPlan.endDate.seconds * 1000)
        : actionPlan.endDate;
      let checkDate = actionPlan.checkDate.seconds
        ? new Date(actionPlan.checkDate.seconds * 1000)
        : actionPlan.checkDate;

      this.editOption.id = actionPlan.id;
      this.editOption.hierarchyName = actionPlan.hierarchyName;
      this.editOption.budget = actionPlan.budget + "";
      this.editOption.startDate = this.getFormattedDate(startDate);
      this.editOption.endDate = this.getFormattedDate(endDate);
      this.editOption.checkDate = checkDate
        ? this.getFormattedDate(checkDate)
        : "";
      this.editOption.status = actionPlan.status;
      this.editOption.actionPlan = actionPlan.text;
      this.editOption.inCharge = actionPlan.inCharge;
      this.editOption.expense = actionPlan.expense + "";
      this.editOption.currency = actionPlan.currency;
      this.editOption.impact = actionPlan.impact;
      this.editOption.description = actionPlan.description || "";
      this.editOption.reviewer = actionPlan.reviewer || "";
      this.isEditModalOpen = true;
    },

    editPlan() {
      let actionPlan = {
        id: this.editOption.id,
        text: this.editOption.actionPlan,
        hierarchyName: this.editOption.hierarchyName,
        startDate: new Date(this.editOption.startDate + "T00:00"),
        endDate: new Date(this.editOption.endDate + "T00:00"),
        checkDate: this.editOption.checkDate
          ? new Date(this.editOption.checkDate + "T00:00")
          : "",
        budget: this.editOption.budget,
        status: this.editOption.status,
        expense: this.editOption.expense,
        inCharge: this.editOption.inCharge,
        currency: this.editOption.currency,
        impact: this.editOption.impact,
        description: this.editOption.description,
        reviewer: this.editOption.reviewer
      };
      this.editActionPlan(actionPlan);

      this.isEditModalOpen = false;
    },

    getCurrencySymbol(code) {
      return this.currencies.filter(currency => currency.code === code)[0]
        .symbol;
    },

    getCategoryName(optionName) {
      let categoryName = "";
      this.categories.forEach(category => {
        let result = category.options.filter(
          option => option.name === optionName
        );
        if (result.length) categoryName = category.name;
      });
      return categoryName;
    },

    //ya no
    getSatisfactionIndexByHierarchy() {
      let answersByHierarchy = {};
      let satisfactionIndexByHierarchy = {};
      this.categories
        .reduce((acc, cur) => {
          return acc.concat(cur.options);
        }, [])
        .forEach(option => (answersByHierarchy[option.name] = []));
      this.answers.forEach(e => {
        this.categories.forEach(category => {
          let userHierarchyValue = e.features[category.name];
          if (userHierarchyValue != "N/A")
            answersByHierarchy[userHierarchyValue].push(
              ...e.multipleChoiceAnswers
            );
        });
      });

      let satisfactionIndex;
      let total;
      Object.keys(answersByHierarchy).forEach(key => {
        satisfactionIndex = [0, 0];
        answersByHierarchy[key].forEach(answer => {
          if (answer.answer.text) {
            if (
              this.scale.find(option => option.text === answer.answer.text)
                .isPositive
            ) {
              satisfactionIndex[0] += 1;
            } else satisfactionIndex[1] += 1;
          } else {
            console.log("Empty answer");
            console.log(answer);
          }
        });
        total = satisfactionIndex[0] + satisfactionIndex[1];
        if (total != 0)
          satisfactionIndexByHierarchy[key] = [
            ((satisfactionIndex[0] / total) * 100).toFixed(0),
            ((satisfactionIndex[1] / total) * 100).toFixed(0)
          ];
        else satisfactionIndexByHierarchy[key] = [0, 0];
      });
      return satisfactionIndexByHierarchy;
    },

    //ya no
    getSatisfactionIndexBySectionAndHierarchy(sectionId, category, option) {
      let answers = [...this.answers];
      let answersBySectionAndHierarchy = [];
      answers
        .filter(answer => answer.features[category] === option)
        .forEach(answer => {
          answersBySectionAndHierarchy.push(
            ...answer.multipleChoiceAnswers.filter(
              ans => ans.section.id === sectionId
            )
          );
        });

      let satisfactionIndex;
      let total;
      satisfactionIndex = [0, 0];

      answersBySectionAndHierarchy.forEach(answer => {
        if (answer.answer.text) {
          if (
            this.scale.find(option => option.text === answer.answer.text)
              .isPositive
          ) {
            satisfactionIndex[0] += 1;
          } else satisfactionIndex[1] += 1;
        } else {
          console.log("Empty answer");
          console.log(answer);
        }
      });
      total = satisfactionIndex[0] + satisfactionIndex[1];
      let satisfactionIndexBySectionAndHierarchy = [0, 0];
      if (total != 0)
        satisfactionIndexBySectionAndHierarchy = [
          ((satisfactionIndex[0] / total) * 100).toFixed(0),
          ((satisfactionIndex[1] / total) * 100).toFixed(0)
        ];
      return satisfactionIndexBySectionAndHierarchy;
    }
  },

  computed: {
    ...mapState({
      actionPlans: state => state.actionPlans.filter(ap => !ap.archived),
      categories: state =>
        state.filters.filters.filter(filter => filter.orgHierarchy)
    }),
    ...mapState(["company", "answers", "survey"]),
    ...mapState("sections", ["sections"]),
    ...mapState("scale", ["scale"]),

    downloadLink() {
      let csvFileData = this.getReportCSV();
      var csvContent = "\uFEFF" + csvFileData;
      var blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      var url = URL.createObjectURL(blob);
      return url;
    },

    filteredPlans() {
      return this.search
        ? this.actionPlans.filter(
            ({ status, inCharge, hierarchyName, dimensionName }) =>
              [status, inCharge, hierarchyName, dimensionName].some(
                prop => prop.toLowerCase() === this.search.toLowerCase()
              )
          )
        : this.actionPlans;
    }
  },

  async mounted() {
    try {
      //await this.fetchCurrentSurvey(this.company.id);
      await this.fetchSections();
      await this.fetchScale();
      //await this.fetchAllAnswers();
      if (!this.actionPlans.length) await this.fetchActionPlans();
      await this.fetchFilters();
    } catch (err) {
      console.log("error fetching");
    }
    this.isDataReady = true;
    //this.hierarchySatisfactionIndex = this.getSatisfactionIndexByHierarchy();
    this.defaultAddOption = {
      hierarchyName: this.categories[0]
        ? this.categories[0].options[0].name
        : "",
      budget: "0",
      expense: "0",
      startDate: "2022-01-01",
      endDate: "2022-01-01",
      actionPlan: "",
      inCharge: "",
      currency: "PEN",
      dimensionId: this.sections.filter(
        section => section.id !== "engagement" && section.id !== "enps"
      )[0].id,
      impact: "Alto",
      description: ""
    };
    this.addOption = { ...this.defaultAddOption };
  }
};
</script>

<style scoped>
.action-plans {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.action-plans > div:first-child {
  margin: 1em;
}

.action-plans > div:nth-child(2) label {
  max-width: 50em;
  min-width: 50em;
}

p {
  width: fit-content;
  overflow-wrap: break-word;
  line-height: 100%;
  margin: 0.5em;
  text-align: center;
  font-size: var(--lengthSm3);
  font-weight: var(--medium);
}

form {
  margin: auto;
}

form > * {
  display: flex;
  justify-content: space-between;
}

form > *:not(:last-child) {
  margin-bottom: 1em;
}

.form-flex {
  gap: 2%;
}

form > div:last-child {
  margin-top: var(--lengthMd3);
}

.input {
  flex-grow: inherit;
}

form > * label {
  width: 32%;
}

.modal-buttons > button {
  width: 40%;
}

.action-plans-container {
  overflow: auto;
  flex-grow: 1;
  margin-top: var(--lengthMd3);
}

.action-plans-table {
  width: fit-content;
  font-weight: var(--medium);
}

.row {
  display: grid;
  grid-template-columns:
    calc(18px + var(--lengthSm3) * 2)
    calc(18px + var(--lengthSm3) * 2)
    250px
    calc(var(--hierarchySize) * 175px)
    /* calc(var(--lengthSm3) * 2 + 4ch)
    calc(var(--lengthSm3) * 2 + 4ch) */
    175px
    /* calc(var(--lengthSm3) * 2 + 4ch)
    calc(var(--lengthSm3) * 2 + 4ch) */
    repeat(10, 150px);
  align-items: stretch;
  border-bottom: 1px solid var(--grayColor2);
}

.row.done {
  background-color: #ffeea2;
}

.row span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--lengthSm2) var(--lengthSm1);
}

.row.header span {
  justify-content: center;
  padding: var(--lengthMd1) var(--lengthSm2);
}

.row:not(.level) {
  justify-content: center;
  text-align: center;
}

.row.header {
  border: 1px solid var(--grayColor2);
  border-top-left-radius: var(--lengthSm2);
  border-top-right-radius: var(--lengthSm2);
  color: white;
  color: var(--fontColor3);
  letter-spacing: 1px;
  text-transform: uppercase;
  background-color: var(--grayColor1);
}

.row.level {
  grid-template-columns: 1fr;
}

.row-name p {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: left;
  padding-bottom: 0.2em;
}

.row .row-name {
  position: relative;
  justify-content: left;
}

.row-name .tooltip {
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.8);
  white-space: nowrap;
  color: white;
  text-align: center;
  border-radius: var(--lengthSm1);
  padding: 0.8em 1.5em;
  position: absolute;
  left: 0;
  top: -1.7em;
  z-index: 1;
}

.row-name:hover .tooltip {
  visibility: visible;
}

.row span + span {
  border-left: 1px solid var(--grayColor2);
}

.row:not(.header) > span:first-child {
  border-left: 1px solid var(--grayColor2);
}

.row:not(.header) > span:last-child {
  border-right: 1px solid var(--grayColor2);
}

.row.hidden {
  display: none;
}

.row .btn {
  padding: 0.75rem var(--lengthSm3);
}

.row > span:first-child {
  display: flex;
  justify-content: center;
}

.hierarchy > span {
  width: calc(100% / var(--hierarchySize));
}

.label__add {
  width: 50%;
}

.btn-container {
  display: flex;
  gap: 0.5em;
}
</style>
